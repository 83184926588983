import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOneBefor:
      'BioTech firms all around the world are actively exploring and investing to reap the benefits by strategically aligning digital trends to more efficiently and intelligently gain access to predictive analytics, supplier management, and contract obligation optimization.',
    ParaOneAnchor: 'Raindrop',
    ParaOneAfter:
      'provides a rapidly deployed and easy-to-use complete Enterprise Spend Management platform, to gain access to actionable intelligence with just a few clicks. Future-ready leaders are investing their time and money to ramp up their business growth, by improving the process efficiency of manufacturing. Raindrop supports the BioTech industry by providing the necessary tools and value required to build supplier visibility with a true 360 degree view of your entire supplier footprint across your enterprise. This helps to ensure the key deliverables and supplier engagement. Implementing modern technologies, like artificial intelligence, cloud, and automation in the supply chain in the biotech industry results in error-free production.',
    AnchorLink: 'https://raindrop.com/resources/TheFutureofSupplyChainandProcurement',
    HeadingOne: 'Partner with Raindrop to be Future-ready',
    HeadingOnePointOne: 'Supplier Relationship Management (SRM)',
    ParaTwo:
      'Raindrop’s Supplier Relationship Management (SRM) module provides insights into how your suppliers are performing. Raindrop makes it easy to understand the status of your suppliers, from onboarding to continuous improvement. It further leads to more informed and data-driven decisions.',
    HeadingOnePointTwo: 'Regulatory Compliances and Standards',
    ParaThree:
      'Manufacturers in the biopharma industry have to deal with increased scrutiny for regulatory compliances and standards. Digital transformation using AI and ML, provides the benefits of automation, reducing human error, and more rapidly gaining access to value. Raindrop helps in turning complex portfolios into simple dashboards with segmentation and offering transparency at all stages.',
    HeadingOnePointThree: 'Real-time Tracking of Key Performance Indicators',
    ParaFour:
      'Monitoring and measuring KPIs in real-time is critical to understanding any given project performance. Biotech companies evaluate and work on critical KPIs related to their supply base and use the proper tools to support these needs.',
    ParaFiveBefor: 'Such areas driving',
    ParaFiveAnchor: 'Service Level Agreement / Key Performance Indicator',
    ParaFiveAfter:
      'are cycle time to onboard suppliers, monitoring supplier compliance, sustainability, and operational risks, leading to a more predictive supplier management framework.',
    AnchorFiveLink: 'https://raindrop.com/solutions/SupplierManagement',
    HeadingTwo: 'Raindrop caters to all your eRFX needs',
    ParaSix:
      'RFx sourcing events like RFI, RFQ, and RFP can be consistently performed in a successful manner by Procurement and functional departments in a fraction of the time. Raindrop Sourcing Events module streamlines and standardizes going out to the market to drive value, reduce cost, and increase output.',
    ParaSeven:
      'Raindrop provides a clean and simple interface, which allows you to quickly focus on the top priorities with respect to an RFx. The quickly accessible dashboard displays your RFx cycle, with your exact stage in real-time and with updated call-to-actions. Moreover, you can quickly refer back to discussions, notes, and meetings with a supplier or a project, which is stored in one easily accessible place in the form of an event and chat channel.',
  },
]
